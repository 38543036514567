import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import { Link } from "gatsby";
import BackToPage from "../images/back-to-page.png";
import TeamDetails from '../components/team-details';
import Nav from '../components/nav';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isSingleLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],
            isCategoryLoaded: false,
            categoryContent: [],
            additional_fields: null,
            isTeamLoaded: false,
            teamItems: [],
            team_slug: null,
        }
    }

    async componentDidMount() {
        this.setState({
            isSingleLoaded: false
        });
        // alert("componentDidMount 1")
        const id = this.props;
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let CMSId = id.params["*"];
        this.setState({ team_slug: CMSId })
        // alert("componentDidMount 2")
        this.getDetailsById(CMSId);
        this.getAllTeams();
        this.getAllTeamCagory();
        // alert("componentDidMount 3")
        // this.getAllCategoryByType();
        // this.getAllBlog();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isSingleLoaded: false
        });
        // alert("componentWillReceiveProps 1")
        console.log("nextProps", nextProps)
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let id = nextProps.params["*"];
        this.setState({ team_slug: id })
        // alert("componentWillReceiveProps 2")
        this.getDetailsById(id);
        this.getAllTeams();
        this.getAllTeamCagory();
        // alert("componentWillReceiveProps 3")
        // this.getAllCategoryByType();
        // this.getAllBlog();
    }

    getDetailsById(id) {
        // alert("getDetailsById 1")
        fetch("https://tatva.com/backendapi/api/v1/cms/by-slug/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSingleLoaded: true,
                        items: result,
                        additional_fields: JSON.parse(result.data.additional_fields)
                    });
                    // alert("getDetailsById 2")
                },
                (error) => {
                    this.setState({
                        isSingleLoaded: true,
                        error
                    });
                }
            )
        // alert("getDetailsById 3")
    }

    async getAllTeams() {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/team")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isTeamLoaded: true,
                        teamItems: result
                    });
                },
                (error) => {
                    this.setState({
                        isTeamLoaded: true,
                        error
                    });
                }
            )
    }


    async getAllTeamCagory() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/team")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });

                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    getCurrentCategorySlug = (categoryContent, items) => {
        console.log("getCurrentCategorySlug", categoryContent, items)
        const itemdetails = categoryContent.filter(book => book.title == items.category)
        // console.log("getCurrentCategorySlug", this.state.items.data.category)
        // console.log("getCurrentCategorySlug", categoryContent.data)
        console.log("getCurrentCategorySlug", itemdetails[0].title)
        return (
            <p><Link to={"/team-by-category/" + itemdetails[0].title + "/#middle_content"} className="author_link">  <img className="icon" src={BackToPage} title="" alt="" /> {itemdetails[0].title} </Link></p>
        )
    }

    next_previous_item = (team_slug, teamItems) => {
        console.log("next_previous_item", team_slug, teamItems)

        // const itemdetails = teamItems.data.filter(book => book.slug == team_slug)
        // console.log("itemdetails", itemdetails)
        // console.log("itemdetails itemdetails", teamItems.indexOf(team_slug));

        const itemdetailsIndex = teamItems.data.findIndex(function (wizard) {
            // return wizard.slug == team_slug;
            return null;
        });
        console.log("itemdetailsIndex", itemdetailsIndex)

        // const Prebutton = teamItems.data.forEach(function (item, itemdetailsIndex) {
        //     return item
        //     // if (itemdetailsIndex > 0) {
        //     //     console.log("Previous: " + JSON.stringify(teamItems.data[itemdetailsIndex - 1].title));
        //     //     return (
        //     //         <div className="previous_btn">
        //     //             <Link to="#nogo"> <strong> {teamItems.data[itemdetailsIndex - 1].title} </strong> Partner </Link>
        //     //         </div>
        //     //     )
        //     // }
        //     // if (itemdetailsIndex < (teamItems.data.length - 1)) {
        //     //     return (
        //     //         <div className="next_btn">
        //     //             <Link to="#nogo"> <strong> A. Sanjay Kishore </strong> Partner </Link>
        //     //         </div>
        //     //     )
        //     // }
        // });



        return (
            <React.Fragment>

                {itemdetailsIndex}
                <div className="clearfix"></div>
            </React.Fragment>
        )

    }

    render() {

        const { isSingleLoaded, isTeamLoaded, teamItems, items, team_slug, isCategoryLoaded, categoryContent } = this.state;

        return (
            <Layout>

                {isSingleLoaded ?
                    <Nav
                        active="team"
                        pageTitle={'Team - ' + items.data?.title}
                        title={items.data?.document_title}
                        description={items.data?.meta_description}
                        keywords={items.data?.meta_tag}
                    ></Nav>
                    : ''}

                {isTeamLoaded ?
                    <Intro
                        search="true"
                        search_data={teamItems.data}
                        title="Our Team"
                        description="We have the largest team of professionals retained by a law firm in states of Telangana and Andhra Pradesh, with experience and expertise in diverse areas of practice.">

                    </Intro>
                    :
                    <Intro
                        search="false"
                        search_data={''}
                        title="Our Team"
                        description="We have the largest team of professionals retained by a law firm in states of Telangana and Andhra Pradesh, with experience and expertise in diverse areas of practice.">

                    </Intro>}

                <div className="main-container" id="team-details-page">
                    <div className="our-practices-image2">
                        <div className="container">

                            {isSingleLoaded && isCategoryLoaded ?
                                <React.Fragment>
                                    <div className="author-info">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                                {this.getCurrentCategorySlug(categoryContent.data, items.data)}
                                            </div>
                                        </div>
                                    </div>

                                    <TeamDetails
                                        data={items.data}>
                                    </TeamDetails>
                                </React.Fragment> : 'Loading'}


                            {/* {isTeamLoaded ?
                                <div className="navigation">
                                    {this.next_previous_item(team_slug, teamItems)}
                                </div>
                                : ''} */}



                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;

